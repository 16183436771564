import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { isCheckTimeout } from '@/utils/auth'
import Qs from 'qs'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在这个位置需要统一的去注入token
    if (store.getters.token) {
      if (isCheckTimeout()) {
        // 登出操作
        store.dispatch('user/logout')
        return Promise.reject(new Error('token 失效'))
      }
      // 如果token存在 注入token
      // config.headers.Authorization = `Bearer ${store.getters.token}`
    }
    config = xjtJson(config)
    // 配置接口国际化
    // config.headers['Accept-Language'] = store.getters.language
    return config // 必须返回配置
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // const { success, message, data } = response.data
    // //   要根据success的成功与否决定下面的操作
    // if (success) {
    //   return data
    // } else {
    //   // 业务错误
    //   ElMessage.error(message) // 提示错误消息
    //   return Promise.reject(new Error(message))
    // }
    return xjtResponse(response)
  },
  error => {
    // 处理 token 超时问题
    if (
      error.response &&
      error.response.data &&
      error.response.data.status === '606'
    ) {
      // token超时
      store.dispatch('user/logout')
    } else {
      ElMessage.error(error.message) // 提示错误信息
    }
    return Promise.reject(error)
  }
)

function xjtJson(config) {
  const params = {}
  if (config.params) {
    for (const param in config.params) {
      if (!config.params[param] && config.params[param] !== 0 && config.params[param] !== false) {
        delete config.params[param]
      }
    }
    params.json = JSON.stringify(config.params)// .replace(/\[/, "%5B").replace(/\]/, "%5D")
  }
  if (store.getters.token) {
    params.access_token = store.getters.token
  }
  // config.params = "json=" + JSON.stringify(config.params) +
  config.params = params
  if (config.method === 'post') {
    config.transformRequest = [(data) => {
      const params = {}
      const token = store.getters.token
      if (token) {
        params.access_token = token
      }
      if (data) {
        const obj = {}
        for (const k in data) {
          if (data[k] instanceof Array && data[k].length === 0) {
            continue
          }
          if (data[k] || data[k] === 0 || data[k] === false) {
            obj[k] = data[k]
          }
        }
        params.json = JSON.stringify(obj)
      }
      return Qs.stringify(params, { arrayFormat: 'brackets' })
    }]
  }
  return config
}

function xjtResponse(response) {
  const res = response.data
  if (res.status !== 200) {
    if (res.status === '603' || res.status === '606' || res.status === 603 || res.status === 606) {
      ElMessage.confirm(
        '你已被登出，可以取消继续留在该页面，或者重新登录',
        '确定登出',
        {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        // token超时
        store.dispatch('user/logout')
        location.reload() // To prevent bugs from vue-router
      })
    } else if (res.status !== 220) {
      if (res.msg) {
        ElMessage.error(res.msg || '') // 提示错误消息
      }
    } else {
      return response.data
    }
    return Promise.reject(new Error(res, 'httpError'))
  } else {
    return response.data
  }
}

export default service

import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import layout from '@/layout'
// import UserManageRouter from './modules/UserManage'
// import RoleListRouter from './modules/RoleList'
// import PermissionListRouter from './modules/PermissionList'
// import ArticleRouter from './modules/Article'
// import ArticleCreaterRouter from './modules/ArticleCreate'
import Maintenance from './modules/Maintenance'
import Check from './modules/Check'
import store from '@/store'

/**
 * 私有路由表
 */
export const privateRoutes = [
  // UserManageRouter,
  // RoleListRouter,
  // PermissionListRouter,
  // ArticleRouter,
  // ArticleCreaterRouter
]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index')
  },
  {
    path: '/',
    component: layout,
    redirect: '/maintenance',
    children: [
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      }
    ]
  },
  Maintenance,
  Check
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach(menu => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  // process.env.NODE_ENV === 'production'
  //   ? createWebHistory()
  //   : createWebHashHistory(),
  routes: publicRoutes
})

export default router

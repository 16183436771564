import layout from '@/layout'

export default {
  path: '/check',
  component: layout,
  redirect: '/check/projects',
  name: 'checkProject',
  meta: { title: '检测报告上传', icon: 'check' },
  children: [
    {
      path: '/check/projects',
      component: () =>
        import(
          /* webpackChunkName: "check" */ '@/views/check/projects'
        ),
      meta: {
        title: '检测客户列表'
      }
    },
    {
      path: '/check/reports',
      component: () =>
        import(
          /* webpackChunkName: "check" */ '@/views/check/reports'
        ),
      meta: {
        title: '检测报告列表',
        activeMenu: '/check/projects'
      }
    }
  ]
}

import layout from '@/layout'

export default {
  path: '/maintenance',
  component: layout,
  redirect: '/maintenance/projects',
  name: 'maintenanceProject',
  meta: { title: '维保报告上传', icon: 'maintenance' },
  children: [
    {
      path: '/maintenance/projects',
      component: () =>
        import(
          /* webpackChunkName: "maintenance" */ '@/views/maintenance/projects'
        ),
      meta: {
        title: '维保客户列表'
      }
    },
    {
      path: '/maintenance/reports',
      component: () =>
        import(
          /* webpackChunkName: "maintenance" */ '@/views/maintenance/reports'
        ),
      meta: {
        title: '维保报告列表',
        activeMenu: '/maintenance/projects'
      }
    }
  ]
}
